@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    scroll-padding-top: 2rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/*variables*/
:root {
    --primary-color: #ffffff;
    --secondary-color: #000000;
    --tertiary-color: #F9A144;
    --text-color: #1E1E1E;
    --sky-blue-color: #1596F5;

    /*variables Text*/
    --mega-header: 4em;
    --mini-header: 3em;
    --header-text: 2em;
    --medium-text: 1.6em;
    --small-text: 1.2em;
    --p-text: 1em;
    --span-text: 0.8em;
    --link-span-text: 0.6em;
}

body,
html {
    overflow-x: hidden;
}

li,
a {
    list-style: none;
    text-decoration: none;
}

.Btn {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    background-color: var(--tertiary-color);
    color: var(--primary-color);
    padding: 10px;
    border-radius: 5px;
    font-size: var(--span-text);
    font-weight: 500;
}

.Btn:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.navCtn {
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding: 10px 5%;
    position: absolute;
    z-index: 888;
    top: 0;
    left: 0;
}

.navCtn.active {
    top: -50%;
}

.navLogo {
    width: 100%;
    height: auto;
}

.navLogo a {
    text-decoration: none;
}

.navLogo a img {
    width: 40px;
    height: auto;
    object-fit: scale-down;
}

.navLinks {
    justify-content: flex-end;
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    gap: 1.5em;
}

.navLinks li {
    list-style: none;
}

.navLinks li a {
    text-decoration: none;
    color: var(--primary-color);
    font-size: var(--span-text);
    flex-wrap: 300;
}

.FooterContactText {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5%;
    text-align: center;
    background-color: rgba(0, 0, 0, .8);
}

.FooterContactText h2 {
    font-size: var(--header-text);
    font-weight: 500;
    color: var(--tertiary-color);
    line-height: 2;
}

.FooterContactText p {
    font-size: var(--span-text);
    font-weight: 300;
    color: var(--primary-color);
    line-height: 2;
}

.copyright {
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 2% 5%;
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.footerLinks {
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.footerLinks li {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.footerLinks li a {
    width: 100%;
    text-decoration: none;
    font-size: var(--span-text);
    font-weight: 300;
    color: var(--primary-color);
}

.footerLinks li a:hover {
    color: var(--tertiary-color);
}

.copyright h1 {
    font-size: var(--span-text);
    font-weight: 300;
    color: var(--primary-color);
    line-height: 2;
    width: 100%;
    text-align: right;
}

.slideInNavigation.active {
    width: 100%;
    max-width: 60%;
    height: auto;
    background-color: var(--secondary-color);
    position: fixed;
    z-index: 555;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 10px;
    transition: all 2s ease;
}

.slideInNavigation {
    width: 100%;
    max-width: 60%;
    height: auto;
    background-color: var(--secondary-color);
    position: fixed;
    z-index: 555;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 10px;
    transition: all 2s ease;
    right: -100%;
}

.slideInNavigationContainer {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-flow: dense;
    position: relative;
    gap: 1em;
}

.slideInNavSection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.slideInNavSection img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.slideInNavContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .8);
}

.slideInNavContent h1 {
    font-size: var(--p-text);
    font-weight: 500;
    color: var(--tertiary-color);
}

.slideInNavContent p {
    font-size: var(--span-text);
    font-weight: 300;
    color: var(--primary-color);
}

.closeBtn {
    width: 20px;
    height: 20px;
    object-fit: scale-down;
    margin-right: auto;
    cursor: pointer;
}

.openMenuBtn {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}


.openMenuBtn img {
    width: 20px;
    height: 20px;
    object-fit: scale-down;
    margin-right: auto;
    cursor: pointer;
}

.responsiveOpenMenuBtn {
    display: none;
}

.responsiveNavigationScreen {
    display: none;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 912px) {

    .navLinks {
        display: none;
    }

    .responsiveOpenMenuBtn {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }


    .responsiveOpenMenuBtn img {
        width: 20px;
        height: 20px;
        object-fit: scale-down;
        margin-right: auto;
        cursor: pointer;
    }

    .responsiveNavigationScreen {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 555;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 1);
        z-index: 999;
        right: -100%;
        transition: all 2s ease;
    }

    .responsiveNavigationScreen.active {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 555;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 1);
        z-index: 999;
        right: 0;
        transition: all 2s ease;
    }

    .responsiveNavigationScreenContainer {
        width: 100%;
        max-width: 600px;
        height: 100%;
        max-height: 600px;
        border-radius: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--secondary-color);
        gap: 1em;
        padding: 10px;
    }

    .Default-Width {
        width: 100% !important;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 490px) {

    .footerLinks {
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: auto;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .footerLinks li {
        margin-right: auto;
        text-align: left;
    }

    .copyright {
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
    }

}